import React, { useReducer } from 'react';
import ChartContext from './chartContext';
import chartReducer from './chartReducer';
import api from '../../api/api';
import {
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING,
  GET_MATRIX,
  GET_MATRIX_IG,
  GET_MATRIX_SEGMENTATION,
  GET_FB_INSIGHTS,
  GET_GG_YOUTUBE,
  GET_GG_ANALYTICS,
  GET_GG_ADS,
  CREATE_FACEBOOK_ADS,
  GET_FACEBOOK_ADS,
  SET_LOADING_ADS,
  GET_FACEBOOK_ADSETS,
  GET_FACEBOOK_CREATIVES,
  SET_CLEAR_STATE_ADS,
  SET_LOADING_CREATIVES,
  GET_GOOGLE_ADS_AD_GROUP,
  GET_GOOGLE_ADS_KEYWORD,
  SET_LOADING_AD_GROUP,
  SET_LOADING_KEYWORD,
  SET_CLEAR_STATE_GA,
  GET_META_ADSETS,
  GET_TIKTOKS,
  GET_META_ADS_REPORT,
  SET_LOADING_REPORT,
  GET_LEADS_CHART,
  GET_LEADS_BY_TAGS_CHART,
  GET_LEADS_BY_SOURCES_CHART
} from '../types';
import { DataContextRequest } from 'modules/http/helpers/DataContextRequest';

const ChartState = (props) => {
  const initialState = {
    loadingCharts: false,
    error: null,
    matrix: {},
    matrixIg: {},
    matrixCampaigns: [],
    matrixAds: [],
    matrixSegmentation: {},
    matrixAccountName: '',
    fbInsights: {},
    fbInsightsPost: [],
    igCards: {},
    igAge: {},
    ytInfo: {},
    ytVideos: [],
    analytics: [],
    googleAds: [],
    googleAdsCampaigns: [],
    googleAdsAudiencie: {},
    responseCreated: {},
    fbData: {},
    fbAdsets: [],
    fbAdcreatives: [],
    loadingAds: false,
    loadingAdCreatives: false,
    adGroups: [],
    keywords: [],
    loadingAdGroup: false,
    loadingKeyword: false,
    tiktokInfo: {},
    tiktokVideos: [],
    loadingReport: false,
    metaAdsReport: null,
    [GET_LEADS_CHART]: {},
    [GET_LEADS_BY_TAGS_CHART]: {},
    [GET_LEADS_BY_SOURCES_CHART]: {}
  };

  const [state, dispatch] = useReducer(chartReducer, initialState);

  //Get Matrix
  const getMatrix = async (values) => {
    setLoading();
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      const res = await api.post(`/charts/facebookAds/campaigns`, { ...values }, config);
      dispatch({
        type: GET_MATRIX,
        payload: {
          data: res.data.matrix,
          campaigns: res.data.campaigns,
          adsets: res.data.adsets,
          accountName: res.data.accountName
        }
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getMetaAdsHome = async ({ account, date, user }) => {
    setLoading();
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };

      const res = await api.post(`/charts/facebookAds/metaAdsets`, { account, date, user }, config);
      dispatch({
        type: GET_META_ADSETS,
        payload: {
          data: res.data.matrix,
          campaigns: res.data.campaigns,
          accountName: res.data.accountName
        }
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getMatrixInstagram = async ({ account, token, date }) => {
    setLoading();
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      try {
        setLoading();
        const res = await api.post(`/charts/matrixIg`, { account, token, date }, config);
        dispatch({
          type: GET_MATRIX_IG,
          payload: {
            data: res.data.matrixIg,
            igCards: res.data.igCards,
            igAge: res.data.igAge
          }
        });
      } catch (err) {
        dispatch({ type: SET_ERROR, payload: err.response.data.error });
      }
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Get Matrix
  const getMatrixSegmentation = async ({ accounts, date, user }) => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      setLoading();
      const res = await api.post(`/charts/matrixSegmentation`, { accounts, date, user }, config);
      dispatch({ type: GET_MATRIX_SEGMENTATION, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getFBInsights = async ({ account, token, date }) => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post(`/charts/matrixFBInsights`, { account, token, date }, config);
      dispatch({
        type: GET_FB_INSIGHTS,
        payload: { data: res.data.fbinsights, post: res.data.post }
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getMatrixYoutube = async ({ account, date, user, token }) => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post(`/charts/matrixYoutube`, { account, date, user, token }, config);
      dispatch({
        type: GET_GG_YOUTUBE,
        payload: { data: res.data.ytInfo, videos: res.data.ytVideos }
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const getMatrixAnalytics = async ({ account, date, user }) => {
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post(`/charts/matrixAnalytics`, { account, date, user }, config);
      dispatch({
        type: GET_GG_ANALYTICS,
        payload: res.data.data
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //GET GOOGLE ADS
  const getMatrixGoogleAds = async ({ account, date, user, token }) => {
    clearCharts();
    setLoading();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    try {
      const res = await api.post(`/charts/googleAds`, { account, date, user, token }, config);
      dispatch({
        type: GET_GG_ADS,
        payload: {
          googleAds: res.data.googleAds,
          googleAdsAudiencie: res.data.googleAdsAudiencie,
          googleAdsCampaigns: res.data.googleAdsCampaigns
        }
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  const createFacebookAds = async (values) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    setLoading();
    try {
      const res = await api.post('/charts/create/fbAds', { ...values }, config);
      dispatch({
        type: CREATE_FACEBOOK_ADS,
        payload: res.data.data
      });
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: error.response.data });
    }
  };

  const getFacebookAd = async (values) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
    clearCharts();
    setLoading();
    try {
      const res = await api.post('/charts/get/fbAds', { ...values }, config);
      dispatch({
        type: GET_FACEBOOK_ADS,
        payload: res.data.data
      });
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: error.response.data });
    }
  };

  const getFacebookAdByAdsets = async (values) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      setLoadingAds();
      const res = await api.post(`/charts/facebookAds/adsets`, { ...values }, config);
      dispatch({
        type: GET_FACEBOOK_ADSETS,
        payload: {
          data: res.data.adsets
        }
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };
  const getFacebookAdByAdCreative = async (values) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      setLoadingCreative();
      const res = await api.post(`/charts/facebookAds/creatives`, { ...values }, config);
      dispatch({
        type: GET_FACEBOOK_CREATIVES,
        payload: {
          data: res.data.ads
        }
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getGoogleAdsByAdGroup = async (values) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      setLoadingAdGroup();
      const res = await api.post(`/charts/google/ads/adGroup`, { ...values }, config);
      dispatch({
        type: GET_GOOGLE_ADS_AD_GROUP,
        payload: {
          data: res.data.data
        }
      });
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  const getGoogleAdsByKeyword = async (values) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      setLoadingKeyword();
      const res = await api.post(`/charts/google/ads/keyword`, { ...values }, config);
      dispatch({
        type: GET_GOOGLE_ADS_KEYWORD,
        payload: {
          data: res.data.data
        }
      });
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: error.response.data.error });
    }
  };

  const getTiktoks = async (values) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      setLoading();
      const res = await api.post('/charts/tiktok/insights', { ...values }, config);
      dispatch({
        type: GET_TIKTOKS,
        payload: { data: res.data.data, videos: res.data.videos }
      });
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: error.response.data.error });
    }
  };

  //Clear State
  const clearCharts = () => dispatch({ type: CLEAR_STATE });

  const clearFBAds = () => dispatch({ type: SET_CLEAR_STATE_ADS });

  const clearGoogleAds = () => dispatch({ type: SET_CLEAR_STATE_GA });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  const setLoadingReport = () => dispatch({ type: SET_LOADING_REPORT });

  const setLoadingAds = () => dispatch({ type: SET_LOADING_ADS });

  const setLoadingCreative = () => dispatch({ type: SET_LOADING_CREATIVES });

  const setLoadingAdGroup = () => dispatch({ type: SET_LOADING_AD_GROUP });

  const setLoadingKeyword = () => dispatch({ type: SET_LOADING_KEYWORD });

  const getMetaAdsReport = async (values) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };
      setLoadingReport();
      const res = await api.post('/charts/ads', { ...values }, config);
      dispatch({
        type: GET_META_ADS_REPORT,
        payload: res.data
      });
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: error.response.data.error });
    }
  };

  const NewChart = (chartType) =>
    new DataContextRequest({ dispatch, setLoading, url: '/charts/reports', dispatchType: chartType, errorType: SET_ERROR });

  const getChart = (values, chartType) => NewChart(chartType).post({ ...values, chartType });

  return (
    <ChartContext.Provider
      value={{
        ...state,
        getChart,
        loadingCharts: state.loadingCharts,
        error: state.error,
        loadingAds: state.loadingAds,
        loadingAdCreatives: state.loadingAdCreatives,
        loadingAdGroup: state.loadingAdGroup,
        loadingKeyword: state.loadingKeyword,

        getMatrix,
        getMetaAdsHome,
        matrix: state.matrix,
        matrixCampaigns: state.matrixCampaigns,
        matrixAds: state.matrixAds,
        matrixAccountName: state.matrixAccountName,

        getMatrixInstagram,
        matrixIg: state.matrixIg,
        igCards: state.igCards,
        igAge: state.igAge,

        getMatrixSegmentation,
        matrixSegmentation: state.matrixSegmentation,

        getFBInsights,
        fbInsightsPost: state.fbInsightsPost,
        fbInsights: state.fbInsights,

        createFacebookAds,
        responseCreated: state.responseCreated,

        getFacebookAd,
        fbData: state.fbData,

        getMatrixYoutube,
        ytInfo: state.ytInfo,
        ytVideos: state.ytVideos,

        getMatrixAnalytics,
        analytics: state.analytics,

        getMatrixGoogleAds,
        googleAds: state.googleAds,
        googleAdsAudiencie: state.googleAdsAudiencie,
        googleAdsCampaigns: state.googleAdsCampaigns,

        getGoogleAdsByAdGroup,
        adGroups: state.adGroups,

        getGoogleAdsByKeyword,
        keywords: state.keywords,

        getTiktoks,
        tiktokInfo: state.tiktokInfo,
        tiktokVideos: state.tiktokVideos,

        clearCharts,
        clearFBAds,
        clearGoogleAds,
        setLoading,
        setLoadingAds,
        setLoadingCreative,
        setLoadingAdGroup,
        setLoadingKeyword,

        getFacebookAdByAdsets,
        fbAdsets: state.fbAdsets,

        getFacebookAdByAdCreative,
        fbAdcreatives: state.fbAdcreatives,

        getMetaAdsReport,
        loadingReport: state.loadingReport,
        metaAdsReport: state.metaAdsReport
      }}
    >
      {props.children}
    </ChartContext.Provider>
  );
};

export default ChartState;
