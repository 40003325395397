export const DEFAULT_AUTH_STATE = {
  staticLookupData: null,
  token: null,
  firstLoad: false,
  success: null,
  isAuthenticated: false,
  user: {
    permissions: []
  },
  loading: false,
  error: null,
  statusCode: null
};
