import {
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGIN_FAIL,
  CLEAR_STATE,
  SET_LOADING,
  SET_ERROR,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  FORGOT_PASSWORD,
  UPDATE_PASSWORD,
  RESET_PASSWORD_FAIL,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD,
  UPDATE_PROFILE,
  GET_STATIC_LOOKUP_DATA,
  SET_AUTH_USER
} from '../types';

import CustomAlert from 'components/CustomAlert';

import { DEFAULT_AUTH_STATE } from './constants';

// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case SET_AUTH_USER: {
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null
      };
    }
    case GET_STATIC_LOOKUP_DATA:
      return {
        ...state,
        staticLookupData: action.payload,
        loading: false,
        error: null
      };
    case CLEAR_STATE:
      return {
        ...state
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case USER_LOADED:
      return {
        ...state,
        firstLoad: true,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
        success: null,
        error: null
      };
    case SET_ERROR:
      CustomAlert({
        title: action?.payload?.error || action.payload,
        icon: 'error'
      });
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loading: false,
        error: null
      };
    case UPDATE_PASSWORD:
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        ...action.payload,
        success: 'Password change successfully',
        isAuthenticated: true,
        loading: false,
        error: null
      };
    case LOGIN_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusCode: action.payload.statusCode
      };
    case RESET_PASSWORD_FAIL:
    case FORGOT_PASSWORD_FAIL:
    case REGISTER_FAIL:
    case LOGOUT:
      localStorage.clear();

      return {
        ...state,
        ...DEFAULT_AUTH_STATE,
        error: action.payload
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        success: 'An email has sent to you, check out your inbox',
        loading: false,
        error: null
      };
    case RESET_PASSWORD:
      return {
        ...state,
        success: 'Password was successfully updated',
        loading: false,
        error: null
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        loading: false,
        error: null,
        user: action.payload
      };
    default:
      return { ...state };
  }
};
