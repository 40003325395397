import { AxiosError } from 'axios';
import api from '../../../api/api';

class APIRequest {
  getConfig() {
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    };
  }
}

interface IDataContextRequest {
  dispatch: Function;
  setLoading: Function;
  url: string;
  dispatchType: string;
  errorType: string;
}

export class DataContextRequest extends APIRequest {
  dispatch: Function;
  setLoading: Function;
  url: string;
  dispatchType: string;
  errorType: string;

  constructor({ dispatch, setLoading, url, dispatchType, errorType }: IDataContextRequest) {
    super();

    this.dispatch = dispatch;
    this.setLoading = setLoading;
    this.url = url;
    this.dispatchType = dispatchType;
    this.errorType = errorType;
  }

  async post(values) {
    try {
      this.setLoading();
      const res = await api.post(this.url, { ...values }, this.getConfig());

      this.dispatch({
        type: this.dispatchType,
        payload: res.data
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        this.dispatch({
          type: this.errorType,
          payload: error.response?.data?.error || error.message
        });
      }
    }
  }
}
